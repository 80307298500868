import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

const HomeFooter = () => {
  return (
    <footer className="bg-[#1A0033] text-[#E0CFFF]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">About AI Guru</h3>
            <p>
              Empowering careers through cutting-edge AI education and professional development.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/courses" className="hover:text-[#9A33FF]">Courses</Link></li>
              <li><Link to="/aboutus" className="hover:text-[#9A33FF]">About Us</Link></li>
              <li><Link to="/contactUs" className="hover:text-[#9A33FF]">Contact</Link></li>
              <li><Link to="/faq" className="hover:text-[#9A33FF]">FAQ</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Legal</h3>
            <ul className="space-y-2">
              <li><a href="/termsofservice" className="hover:text-[#9A33FF]">Terms of Service</a></li>
              <li><a href="/privacy-policy" className="hover:text-[#9A33FF]">Privacy Policy</a></li>
              <li><a href="#" className="hover:text-[#9A33FF]">Cookie Policy</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-[#9A33FF]"><Facebook size={24} /></a>
              <a href="#" className="hover:text-[#9A33FF]"><Twitter size={24} /></a>
              <a href="#" className="hover:text-[#9A33FF]"><Instagram size={24} /></a>
              <a href="#" className="hover:text-[#9A33FF]"><Linkedin size={24} /></a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-[#320066] text-center">
          <p className="text-[#E0CFFF]">© 2024 AI Guru. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
