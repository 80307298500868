import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-50">
      {/* Page Header */}
      <div className="w-full py-10 bg-blue-50">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-gray-800 pt-7">Privacy Policy</h1>
          <p className="text-sm text-gray-600 mt-2">Last Updated: October 10, 2024</p>
        </div>
      </div>

      {/* Policy Content */}
      <div className="py-12 bg-white">
        <div className="max-w-5xl mx-auto px-6 space-y-10">

          {/* Introduction */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Introduction</h2>
            <p className="text-base text-gray-600 mt-2">
              AI Guru ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website goaiguru.com, use our mobile application, or use our services (collectively, the "Services"). 
              Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Services.
            </p>
          </section>

          {/* Information We Collect */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Information We Collect</h2>
            <p className="text-base text-gray-600 mt-2">
              We collect information that you provide directly to us, information we obtain automatically when you use our Services, and information from third-party sources.
            </p>
            <h3 className="text-lg font-semibold text-gray-800 mt-4">Information You Provide to Us</h3>
            <p className="text-base text-gray-600 mt-2">
              <strong>Account Information:</strong> When you create an account, we collect your name, email address, password, and other optional profile information.
            </p>
            <p className="text-base text-gray-600 mt-2">
              <strong>Course Information:</strong> We collect information related to the courses you enroll in, your progress, quiz results, and any submissions or contributions you make to the courses.
            </p>
            <p className="text-base text-gray-600 mt-2">
              <strong>Payment Information:</strong> If you make a purchase, we collect payment information, which may include credit card numbers, billing addresses, and other financial information.
            </p>
            <p className="text-base text-gray-600 mt-2">
              <strong>Communications:</strong> We collect information you provide when you respond to surveys, communicate with us via email or chat, or post to our forums.
            </p>

            <h3 className="text-lg font-semibold text-gray-800 mt-4">Information We Collect Automatically</h3>
            <p className="text-base text-gray-600 mt-2">
              <strong>Usage Information:</strong> We collect information about your interactions with our Services, such as the pages or content you view, your searches, and other actions on our Services.
            </p>
            <p className="text-base text-gray-600 mt-2">
              <strong>Device Information:</strong> We collect information about the device and software you use to access our Services, including IP address, web browser type, operating system version, and device identifiers.
            </p>
            <p className="text-base text-gray-600 mt-2">
              <strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to collect information about your browsing behavior and preferences.
            </p>
          </section>

          {/* How We Use Your Information */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">How We Use Your Information</h2>
            <p className="text-base text-gray-600 mt-2">
              We use the information we collect for various purposes, including:
            </p>
            <ul className="list-disc list-inside text-base text-gray-600 mt-2 space-y-2">
              <li>Providing, maintaining, and improving our Services</li>
              <li>Processing transactions and sending related information, including confirmations and invoices</li>
              <li>Sending you technical notices, updates, security alerts, and support and administrative messages</li>
              <li>Responding to your comments, questions, and requests</li>
              <li>Personalizing and improving your experience on our Services</li>
              <li>Monitoring and analyzing trends, usage, and activities in connection with our Services</li>
              <li>Detecting, investigating, and preventing fraudulent transactions and other illegal activities</li>
            </ul>
          </section>

          {/* Sharing of Information */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Sharing of Information</h2>
            <p className="text-base text-gray-600 mt-2">
              We may share your information in the following situations:
            </p>
            <ul className="list-disc list-inside text-base text-gray-600 mt-2 space-y-2">
              <li>With service providers, contractors, and other third parties we use to support our business</li>
              <li>To comply with applicable laws and regulations</li>
              <li>To respond to a subpoena, search warrant, or other lawful requests for information we receive</li>
              <li>To protect our rights, property, or safety, and that of our users or others</li>
            </ul>
          </section>

          {/* Your Choices */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Your Choices</h2>
            <p className="text-base text-gray-600 mt-2">
              You have certain choices about how we use your information:
            </p>
            <ul className="list-disc list-inside text-base text-gray-600 mt-2 space-y-2">
              <li>
                <strong>Account Information:</strong> You may update, correct, or delete your account information at any time by logging into your account or contacting us.
              </li>
              <li>
                <strong>Marketing Communications:</strong> You may opt out of receiving promotional emails from us by following the instructions in those emails.
              </li>
              <li>
                <strong>Cookies:</strong> Most web browsers are set to accept cookies by default. You can usually choose to set your browser to remove or reject browser cookies.
              </li>
            </ul>
          </section>

          {/* Data Security */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Data Security</h2>
            <p className="text-base text-gray-600 mt-2">
              We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.
            </p>
          </section>

          {/* Children's Privacy */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Children's Privacy</h2>
            <p className="text-base text-gray-600 mt-2">
              Our Services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.
            </p>
          </section>

          {/* Changes to Our Privacy Policy */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Changes to Our Privacy Policy</h2>
            <p className="text-base text-gray-600 mt-2">
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
            </p>
          </section>

          {/* Contact Information */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">Contact Information</h2>
            <p className="text-base text-gray-600 mt-2">
              If you have any questions about this Privacy Policy, please contact us at:
              <a href="mailto:hello@goaiguru.com" className="text-blue-600 hover:underline"> hello@goaiguru.com</a>
            </p>
          </section>

          {/* California Residents */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">For California Residents</h2>
            <p className="text-base text-gray-600 mt-2">
              If you are a California resident, you have certain rights with respect to your personal information pursuant to the California Consumer Privacy Act (CCPA). For more information about these rights and how to exercise them, please see our 
              <a href="#" className="text-blue-600 hover:underline"> California Privacy Notice</a>.
            </p>
          </section>

          {/* European Union Residents */}
          <section>
            <h2 className="text-2xl font-semibold text-gray-900">For European Union Residents</h2>
            <p className="text-base text-gray-600 mt-2">
              If you are a resident of the European Union, you have certain rights under the General Data Protection Regulation (GDPR). For more information about these rights and how we handle data from EU residents, please see our 
              <a href="#" className="text-blue-600 hover:underline"> GDPR Compliance Statement</a>.
            </p>
          </section>
        </div>
      </div>

    </div>
  );
};

export default PrivacyPolicy;

