import React from "react";

const TermsOfService = () => {
  return (
    <div className="bg-gray-50">
      {/* Page Title */}
      <div className="w-full py-10 bg-blue-50">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-4xl font-bold text-gray-800 pt-7">Terms of Service</h1>
          <p className="text-sm text-gray-600 mt-2">Last Updated: October 10, 2024</p>
        </div>
      </div>

      {/* Terms Content */}
      <div className="py-12 bg-white">
        <div className="max-w-5xl mx-auto px-6 space-y-10">
          {/* 1. Acceptance of Terms */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">1. Acceptance of Terms</h2>
            <p className="text-base text-gray-600 mt-2">
              Welcome to AI Guru. By accessing or using our website, courses, and services
              (collectively, the "Services"), you agree to be bound by these Terms of Service
              ("Terms"). If you do not agree to these Terms, please do not use our Services.
            </p>
          </section>

          {/* 2. Changes to Terms */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">2. Changes to Terms</h2>
            <p className="text-base text-gray-600 mt-2">
              We reserve the right to modify these Terms at any time. We will always post the
              most current version on our site. By continuing to use the Services after changes
              become effective, you agree to be bound by the revised Terms.
            </p>
          </section>

          {/* 3. Privacy Policy */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">3. Privacy Policy</h2>
            <p className="text-base text-gray-600 mt-2">
              Your use of the Services is also governed by our{" "}
              <a href="/privacy-policy" className="text-blue-600 hover:underline">
                Privacy Policy
              </a>
              .
            </p>
          </section>

          {/* 4. User Accounts */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">4. User Accounts</h2>
            <div className="space-y-4 mt-2">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">4.1 Registration</h3>
                <p className="text-base text-gray-600">
                  To access certain features of the Services, you must register for an account.
                  You agree to provide accurate, current, and complete information during the
                  registration process.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">4.2 Account Responsibility</h3>
                <p className="text-base text-gray-600">
                  You are responsible for maintaining the confidentiality of your account and
                  password. You agree to accept responsibility for all activities that occur
                  under your account.
                </p>
              </div>
            </div>
          </section>
	  {/* 5. Course Enrollment and Payments */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">5. Course Enrollment and Payments</h2>
            <div className="space-y-4 mt-2">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">5.1 Enrollment</h3>
                <p className="text-base text-gray-600">
                  By enrolling in a course, you agree to pay the specified fees and to follow the
                  course guidelines.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">5.2 Payments</h3>
                <p className="text-base text-gray-600">
                  All payments are processed securely through our payment providers. By providing
                  payment information, you represent that you are authorized to use the payment
                  method.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">5.3 Refunds</h3>
                <p className="text-base text-gray-600">
                  Our refund policy can be found{" "}
                  <a href="/refund-policy" className="text-blue-600 hover:underline">
                    here
                  </a>
                  . We reserve the right to modify this policy at any time.
                </p>
              </div>
            </div>
          </section>

          {/* 6. Intellectual Property Rights */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">6. Intellectual Property Rights</h2>
            <div className="space-y-4 mt-2">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">6.1 Our Content</h3>
                <p className="text-base text-gray-600">
                  All content provided through the Services, including but not limited to videos,
                  texts, graphics, and logos, is owned by AI Guru or its licensors and is
                  protected by copyright and other intellectual property laws.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">6.2 User Content</h3>
                <p className="text-base text-gray-600">
                  By posting content on our platform, you grant AI Guru a non-exclusive,
                  worldwide, royalty-free license to use, modify, publicly perform, publicly
                  display, reproduce, and distribute such content on and through the Services.
                </p>
              </div>
            </div>
          </section>

          {/* Remaining Sections */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">7. Prohibited Conduct</h2>
            <ul className="list-disc list-inside text-base text-gray-600 space-y-2 mt-2">
              <li>Use the Services for any illegal purpose or in violation of any law</li>
              <li>Violate or encourage others to violate the rights of third parties</li>
              <li>Share your account or password with any third party</li>
              <li>Interfere with or disrupt the Services or servers</li>
              <li>Attempt to gain unauthorized access to any part of the Services</li>
            </ul>
          </section>

          {/* Termination, Warranties, Limitation of Liability, Governing Law */}
          {[
            { title: "8. Termination", content: "We reserve the right to terminate or suspend your account..." },
            { title: "9. Disclaimer of Warranties", content: "THE SERVICES ARE PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS..." },
            { title: "10. Limitation of Liability", content: "IN NO EVENT SHALL AI GURU BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES..." },
            { title: "11. Governing Law", content: "These Terms shall be governed by and construed in accordance with the laws of the State of New Jersey..." },
          ].map((section, index) => (
            <section key={index}>
              <h2 className="text-xl font-semibold text-gray-900">{section.title}</h2>
              <p className="text-base text-gray-600 mt-2">{section.content}</p>
            </section>
          ))}

          {/* Contact Information */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900">12. Contact Information</h2>
            <p className="text-base text-gray-600 mt-2">
              If you have any questions about these Terms, please contact us at{" "}
              <a href="mailto:hello@goaiguru.com" className="text-blue-600 hover:underline">
                hello@goaiguru.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>

    </div>
  );
};

export default TermsOfService;

