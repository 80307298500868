import React, { useEffect } from 'react';

const InstructorSignup = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  useEffect(() => {
    if (!document.querySelector('#hubspotInstructorForm > iframe')) {
      const script = document.createElement('script');
      script.src = "https://js.hsforms.net/forms/embed/v2.js";
      script.charset = "utf-8";
      script.type = "text/javascript";
      script.onload = () => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "47638375",
          formId: "ae46b541-f532-4f6e-a6c2-b5e64ae816cd", 
          target: "#hubspotInstructorForm"
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Apply to Become an Instructor</h1>
      <p style={styles.description}>
        Join AI Guru and share your expertise with a global community of learners. Fill out the form below to apply.
      </p>
      <div id="hubspotInstructorForm" style={styles.form}></div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f7fa',
    padding: '20px',
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: '10px',
    color: '#333',
    paddingTop : '40px'
  },
  description: {
    fontSize: '1.25rem',
    marginBottom: '20px',
    textAlign: 'center',
    maxWidth: '600px',
  },
  form: {
    width: '100%',
    maxWidth: '600px', 
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
};

export default InstructorSignup;

