import React , {useEffect} from "react";
import { Link } from "react-router-dom";
import HomeFooter from "./HomeFooter";


const AboutUs = () => {
   useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="relative w-full py-12 bg-blue-50 flex items-center justify-center">
        <div className="max-w-6xl text-center px-4 py-6">
          <h1 className="text-5xl sm:text-7xl font-bold text-gray-900 mb-4">
            About AI Guru
          </h1>
          <p className="text-lg sm:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto leading-relaxed">
            At AI Guru, we are on a mission to democratize AI and Machine Learning education, making these powerful technologies accessible to everyone.
          </p>
        </div>
      </div>

      {/* Our Mission Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Text Column */}
          <div className="md:order-2 flex flex-col justify-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-gray-800 mb-4">
              Our Mission
            </h2>
            <p className="text-lg sm:text-xl text-gray-600 mb-6">
	  We believe that the power of artificial intelligence should be accessible to all, not just a select few. Our mission is to empower individuals from all walks of life with the knowledge and skills needed to thrive in an AI-driven future. Our platform is designed to bridge the gap between technology and people by providing high-quality, affordable education in AI and Machine Learning.
            </p>
          </div>

          {/* Image Column */}
          <div className="flex items-center justify-center">
            <img src="https://aigurufiles.s3.amazonaws.com/images/AboutImg.jpg" alt="About Us" />
          </div>
        </div>
      </div>

      {/* Our Vision and Values Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:pr-8">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Our Vision</h3>
            <p className="text-lg text-gray-600 mb-6">
	  We envision a world where AI literacy is as fundamental as reading and writing. Through GoAIGuru.com, we aim to create a global community of AI-savvy individuals who can harness the potential of this transformative technology to solve real-world problems and drive innovation across all sectors.
            </p>
            <Link
              to="/courses"
              className="inline-block bg-blue-600 text-white font-bold py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Discover Our Courses
            </Link>
          </div>
          <div className="bg-gray-50 rounded-lg shadow-md p-6">
            <h4 className="text-2xl font-bold text-gray-800 mb-4">Our Core Values</h4>
            <ul className="text-lg text-gray-600 space-y-3">
              <li>
                <strong>Accessibility:</strong> Simplifying complex AI concepts into digestible, easy-to-understand modules.
              </li>
              <li>
                <strong>Innovation:</strong> Continuously evolving our curriculum with the rapidly changing AI landscape to ensure you’re always learning cutting-edge techniques.
              </li>
              <li>
                <strong>Practical Application:</strong> Focusing on real-world applications to bridge the gap between theory and practice.
              </li>
              <li>
                <strong>Community:</strong> Fostering a supportive global network of learners and AI enthusiasts.
              </li>
              <li>
                <strong>Ethical AI:</strong> Emphasizing the importance of developing and using AI responsibly and ethically.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* What We Offer Section */}
      <div className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl sm:text-5xl font-bold text-gray-800 mb-8">What We Offer</h2>
          <p className="text-lg sm:text-xl text-gray-600 mb-6 max-w-3xl mx-auto">
	  AI Guru offers comprehensive courses ranging from AI basics to advanced machine learning techniques. Our programs include:
	  <li>	Hands-on Projects: Apply what you learn through practical, real-world projects.</li>
	  <li>	Personalized Learning Paths: Tailor your education to fit your career goals.</li>
	  <li>	Certificates of Completion: Boost your credentials with recognized certifications.</li>
          </p>
          <Link
            to="/courses"
            className="inline-block bg-blue-600 text-white font-bold py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Explore Our Courses
          </Link>
        </div>
      </div>

      {/* Impact by Numbers Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl sm:text-5xl font-bold text-gray-800 mb-8">Our Impact by the Numbers</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-3xl font-bold text-blue-600">10,000+</h3>
              <p className="text-lg text-gray-600">Learners enrolled worldwide</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-3xl font-bold text-blue-600">20+</h3>
              <p className="text-lg text-gray-600">Countries represented</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-3xl font-bold text-blue-600">95%</h3>
              <p className="text-lg text-gray-600">Course completion rate</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-3xl font-bold text-blue-600">92%</h3>
              <p className="text-lg text-gray-600">Career advancement post-completion</p>
            </div>
          </div>
        </div>
      </div>

         {/* Team Section */}
         <div className="py-16 bg-gray-100">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8">Meet Our Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Replace with your team member data */}
	     <a
	     href="https://www.linkedin.com/in/vajariya"
  	     target="_blank"
             rel="noopener noreferrer"
             className="block"
             >
            <div className="bg-white rounded-lg shadow-lg p-6">
              <img
                src="https://aigurufiles.s3.amazonaws.com/images/Ritesh-thumbnail.png"
                alt="Founder"
                className="h-24 w-24 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800">Ritesh Vajariya</h3>
	  <a href="https://RiteshAI.com" target="_blank">
  <p className="text-gray-600">Founder & AI Expert</p>
</a>
            </div>
	    </a>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <img
                src="https://aigurufiles.s3.amazonaws.com/images/manan-1.jpg"
                alt="Team member"
                className="h-24 w-24 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800">Manan</h3>
              <p className="text-gray-600">AI Education Evangelist</p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="py-16 bg-blue-600">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-2xl sm:text-3xl font-extrabold text-white mb-6">
            Join the AI Revolution
          </h2>
          <p className="text-lg text-blue-100 mb-8">
            Whether you're a beginner, a professional, or an organization, AI Guru is your gateway to the future of technology.
          </p>
          <Link
            to="/courses"
            className="inline-block bg-white text-blue-600 font-bold py-2 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
          >
            Start Learning Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

