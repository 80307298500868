import React from "react";
import { Link } from "react-router-dom";

const InstructorsPlaceholder = () => {
  return (
    <div className="bg-gradient-to-r from-pink-100 to-purple-100 py-16 sm:py-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
          Become an Instructor at AI Guru
        </h2>
        <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
          We are looking for talented professionals to share their knowledge with learners from around the world. Join our team and make an impact!
        </p>
        {/* Button to redirect to form */}
          <Link
            to="/instructor-signup"
            className="inline-block bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold py-2 px-6 rounded-lg hover:bg-purple-700 transition duration-300"
          >
            Apply to Become an Instructor
          </Link>
        
      </div>
    </div>
  );
};

export default InstructorsPlaceholder;

