import React from 'react';

const SimpleFooter = () => {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="w-full px-1 sm:px-2 lg:px-3 py-6">
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-500">
            © 2024 AI Guru. All rights reserved.
          </div>
          <div className="flex space-x-6">
            <a href="/termsofservice" className="text-gray-400 hover:text-gray-500">
              Terms of Service
            </a>
            <a href="/privacy-policy" className="text-gray-400 hover:text-gray-500">
              Privacy Policy
            </a>
            <a href="/contactUs" className="text-gray-400 hover:text-gray-500">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
