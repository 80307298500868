import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingScreen from './components/LoadingScreen';
import Header from './components/Header';
import SimpleFooter from './components/SimpleFooter';
import AboutUs from './components/AboutUs';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import InstructorsPlaceholder from './components/InstructorsPlaceholder';
import ContactUs from './components/ContactUs';
import InstructorSignup from './components/InstructorSignup';
import ReactGA from 'react-ga4'; // Import Google Analytics

// Lazy load components
const Home = lazy(() => import('./components/Home'));
const Courses = lazy(() => import('./components/Courses'));
const CourseDetail = lazy(() => import('./components/CourseDetail'));
const CourseContent = lazy(() => import('./components/CourseContent'));
const Profile = lazy(() => import('./components/Profile'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const CourseLandingPage = lazy(() => import('./components/CourseLandingPage'));
const CongratulationsPage = lazy(() => import('./components/CongratulationsPage'));

const TRACKING_ID = 'G-MH1J1103E1'; 
ReactGA.initialize(TRACKING_ID);

const ProtectedRoute = ({ component: Component, ...args }) => {
  const ProtectedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingScreen />,
  });
  return (
    <Suspense fallback={<LoadingScreen />}>
      <ProtectedComponent {...args} />
    </Suspense>
  );
};

const ConditionalHeader = () => {
  const location = useLocation();
  const pathStartsWith = (prefix) => location.pathname.startsWith(prefix);
  const hideHeader = pathStartsWith('/courses/') && !pathStartsWith('/courses/content');
  return hideHeader ? null : <Header />;
};

const ConditionalFooter = () => {
  const location = useLocation();
  return location.pathname === '/' ? null : <SimpleFooter />;
};

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
}

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access start:notebooks',
      }}
    >
      <Router>
        <AnalyticsTracker /> {/* Track page views on route changes */}
        <div className="flex flex-col min-h-screen bg-pink-50">
          <ConditionalHeader />
          <main className="flex-grow">
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/:id" element={<CourseLandingPage />} />
                <Route path="/congratulations" element={<CongratulationsPage />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/termsofservice" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/instructor" element={<InstructorsPlaceholder />} />
                <Route path="/contactUs" element={<ContactUs />} />
                <Route path="/instructor-signup" element={<InstructorSignup />} />
                {/* Protected Routes */}
                <Route path="/courses/:id/enroll" element={<ProtectedRoute component={CourseDetail} />} />
                <Route
                  path="/courses/:courseId/content/:sectionIndex/:lectureIndex"
                  element={<ProtectedRoute component={CourseContent} />}
                />
                <Route
                  path="/courses/:courseId/content/:sectionIndex"
                  element={<ProtectedRoute component={CourseContent} />}
                />
                <Route path="/courses/:courseId/content" element={<ProtectedRoute component={CourseContent} />} />
                <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
                <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                {/* Login Route */}
                <Route path="/login" element={<LoginForm />} />
                {/* Fallback Route */}
                <Route path="*" element={<Home />} />
              </Routes>
            </Suspense>
          </main>
          <ConditionalFooter />
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App;
