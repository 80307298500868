import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { isProtectedRoute } from '../config/routeConfig';

const useApi = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

const apiInstance = useMemo(() => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '/api',
  });

  instance.interceptors.request.use(
    async (config) => {
      // Extract the relative URL (without baseURL)
      const relativeUrl = config.url.replace(config.baseURL || '', '');

      if (isProtectedRoute(relativeUrl)) {
        if (isAuthenticated) {
          try {
            const token = await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: process.env.REACT_APP_AUTH0_SCOPE,
            });
            config.headers.Authorization = `Bearer ${token}`;
          } catch (error) {
            console.error('Error getting access token:', error);
          }
        } else {
          console.warn('Attempting to access protected route without authentication:', relativeUrl);
          // Optionally handle unauthenticated access to protected routes
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
}, [getAccessTokenSilently, isAuthenticated]);

const fetchUserProfile = useCallback(async () => {
  try {
    console.log('Fetching user profile...');
    const token = await getAccessTokenSilently();
  //    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  //    scope: process.env.REACT_APP_AUTH0_SCOPE,
    console.log('Token acquired:', token ? 'Yes' : 'No');
    const response = await apiInstance.get('/user/profile', {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Profile response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

  const updateUserProfile = useCallback(async (profileData) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.put('/user/profile', profileData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

const enrollInCourse = useCallback(async (courseId) => {
  try {
    console.log(`Enrolling in course: ${courseId}`);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
    console.log('Token acquired for enrollment:', token ? 'Yes' : 'No');
    const response = await apiInstance.post(`/courses/${courseId}/enroll`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Enrollment response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error enrolling in course:', error.response ? error.response.data : error.message);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

const fetchEnrolledCourses = useCallback(async () => {
  try {
    console.log('Fetching enrolled courses');
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
    console.log('Token acquired for fetching enrolled courses:', token ? 'Yes' : 'No');
    const response = await apiInstance.get('/courses/enrolled', {
      headers: { Authorization: `Bearer ${token}` }
    });
    const enrolledCourses = response.data;
    console.log('Enrolled courses:', enrolledCourses);

    const coursesWithProgress = await Promise.all(
      enrolledCourses.map(async (course) => {
        const progressResponse = await apiInstance.get(`/courses/${course.id}/progress`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        return { ...course, progress: progressResponse.data.progress };
      })
    );
    console.log('Courses with progress:', coursesWithProgress);
    return coursesWithProgress;
  } catch (error) {
    console.error('Error fetching enrolled courses:', error.response ? error.response.data : error.message);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

const fetchCourses = useCallback(async ({
  search = '',
  topic = '',
  level = '',
  duration = '',
  limit = 9,
  lastEvaluatedKey = null
} = {}) => {
  try {
    const params = {
      search,
      topic,
      level,
      duration,
      limit,
      lastEvaluatedKey: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : null
    };
    // Remove empty params
    Object.keys(params).forEach(key => !params[key] && delete params[key]);

    const response = await apiInstance.get('/public/courses', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
}, [apiInstance]);


const fetchFeaturedCourses = useCallback(async () => {
  try {
    const response = await apiInstance.get('/public/featured');
    console.log('Featured courses response:', response.data);
    return response.data.featuredCourses;
  } catch (error) {
    console.error('Error fetching featured courses:', error);
    throw error;
  }
}, [apiInstance]);

const fetchCourseDetail = useCallback(async (courseId) => {
  try {
    const response = await apiInstance.get(`/public/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course detail:', error);
    throw error;
  }
}, [apiInstance]);

  // Fetch course content from the API
  const fetchCourseContent = useCallback(async (courseId) => {
    try {
      console.log('Fetching course content...');
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get(`/courses/${courseId}/content`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Course content fetched:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching course content:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  // Update course progress in the backend
  const updateCourseProgress = useCallback(async (courseId, progress, completedLecture) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.post(`/courses/${courseId}/progress`, {
        progress: Math.round(progress * 100), // If API expects percentage
        completedLecture,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating course progress:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  // Fetch video URL from the API
  const fetchVideoUrl = useCallback(async (courseId, videoKey) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get(`/courses/${courseId}/video-url`, {
        params: { s3Key: videoKey },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.url;
    } catch (error) {
      console.error('Error fetching video URL:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  // Fetch quiz data
  const fetchQuizData = useCallback(async (courseId, quizId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get(`/courses/${courseId}/quiz/${quizId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching quiz data:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  // Complete course and generate certificate
  const completeCourse = useCallback(async (courseId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.post(`/courses/${courseId}/complete`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error completing course:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  // Fetch course progress
  const getProgress = useCallback(async (courseId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get(`/courses/${courseId}/progress`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching course progress:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);


  const checkEnrollmentStatus = useCallback(async (courseId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get(`/courses/${courseId}/enrollment-status`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.isEnrolled;
    } catch (error) {
      console.error('Error checking enrollment status:', error);
      return false;
    }
  }, [apiInstance, getAccessTokenSilently]);

  const fetchRecommendedCourses = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get('/courses/recommendations', {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching recommended courses:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  const uploadProfilePicture = useCallback(async (file) => {
    try {
    const token = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append('profilePicture', file);
      const response = await apiInstance.post('/user/profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      throw error;
    }
  }, [apiInstance]);

  const submitLearningPathQuestionnaire = useCallback(async (questionnaireData) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.post('/user/learning-path-questionnaire', questionnaireData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting learning path questionnaire:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);

  const fetchQuestionnaire = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.get('/user/questionnaire', {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching questionnaire:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);


  const submitQuizAnswers = useCallback(async (courseId, quizId, userAnswers) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apiInstance.post(`/courses/${courseId}/quiz/${quizId}/submit`, { answers: userAnswers }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting quiz answers:', error);
      throw error;
    }
  }, [apiInstance, getAccessTokenSilently]);



const fetchBookmarks = useCallback(async (courseId) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.get(`/courses/${courseId}/bookmarks`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bookmarks:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

const createBookmark = useCallback(async (courseId, lectureIndex, timestamp) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.post(
      `/courses/${courseId}/bookmarks`,
      { lectureIndex, timestamp },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating bookmark:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

const deleteBookmark = useCallback(async (bookmarkId) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.delete(`bookmarks/${bookmarkId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting bookmark:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);


const generateCertificate = useCallback(async (courseId) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.post(`/courses/${courseId}/generate-certificate`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Certificate generation response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error generating certificate:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

// Fetch certificates for the logged-in user
const fetchCertificates = useCallback(async () => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.get('/courses/certificates', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.certificates;
  } catch (error) {
    console.error('Error fetching certificates:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

// Fetch certificate URL for the logged-in user
const fetchCertificateUrl = useCallback(async (certificateId) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.get(`/courses/certificates/${certificateId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.signedUrl; // Return the signed URL
  } catch (error) {
    console.error('Error fetching certificate URL:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);


const startNotebook = useCallback(async () => {
  try {
    const token = await getAccessTokenSilently();
    console.log('Access Token:', token); // Debugging line to check the token

    // If the token is missing or invalid, log an error message
    if (!token) {
      console.error('Token is missing or invalid');
      return;
    }

    const response = await apiInstance.post(`/courses/start-server`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error('Failed to start notebook session:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);


const fetchNotes = useCallback(async (courseId, sectionIndex, lectureIndex) => {
  try {
    const token = await getAccessTokenSilently();
    console.log('Access token:', token); // Debugging step to ensure token is retrieved
    const response = await apiInstance.get(`/courses/${courseId}/notes/${sectionIndex}/${lectureIndex}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    return { content: '' }; // Return empty content if there's an error
  }
}, [apiInstance, getAccessTokenSilently]);

const saveNote = useCallback(async (courseId, sectionIndex, lectureIndex, content) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await apiInstance.post(`/courses/${courseId}/notes/${sectionIndex}/${lectureIndex}`, 
      { content },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error saving note:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

const createCheckoutSession = useCallback(async (courseId) => {
  try {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
    console.log('Token:', token);  // <-- Log the token to verify it's being retrieved
    if (!token) {
      throw new Error('No token retrieved');
    }

    // Define success and cancel URLs with the courseId

    // Send courseId, success_url, and cancel_url to backend for checkout session creation
    const response = await apiInstance.post('/stripe/create-checkout-session',
      { courseId },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
}, [apiInstance, getAccessTokenSilently]);

  const fetchCoursePrice = useCallback(async (courseId) => {
    try {
      const response = await apiInstance.get(`/courses/${courseId}/price`);
      return response.data;
    } catch (error) {
      console.error('Error fetching course price:', error);
      return null; // Return null instead of throwing an error
    }
  }, [apiInstance]);

const sendChatMessage = useCallback(async (message, context) => {
  try {
    console.log('Sending chat message:', message);
    console.log('Context:', context);
    
    let headers = {
      'Content-Type': 'application/json',
    };
    
    let token;
    try {
      // Attempt to get the access token
      token = await getAccessTokenSilently();
      headers['Authorization'] = `Bearer ${token}`;
      console.log('Got access token');
    } catch (authError) {
      // User is not authenticated
      console.log('User is not authenticated');
    }
    
    const response = await fetch('/api/chat', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ 
        message,
        context: {
          courseId: context?.courseId,
          courseTitle: context?.courseTitle,
          sectionTitle: context?.sectionTitle,
          lectureTitle: context?.lectureTitle
        }
      })
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Chat response:', data);
    return data;
  } catch (error) {
    console.error('Error in sendChatMessage:', error);
    throw error;
  }
}, [getAccessTokenSilently]);


  return {
    fetchUserProfile,
    updateUserProfile,
    enrollInCourse,
    fetchEnrolledCourses,
    fetchCourses,
    fetchFeaturedCourses,
    fetchCourseDetail,
    fetchCourseContent,
    updateCourseProgress,
    checkEnrollmentStatus,
    fetchRecommendedCourses,
    uploadProfilePicture,
    submitLearningPathQuestionnaire,
    fetchQuestionnaire,
    fetchQuizData,
    submitQuizAnswers,
    completeCourse,
    generateCertificate,
    fetchCertificateUrl,
    getProgress,
    fetchBookmarks,
    createBookmark,
    deleteBookmark,
    fetchVideoUrl,
    fetchNotes,
    fetchCertificates,
    fetchCoursePrice,
    createCheckoutSession,
    startNotebook,
    sendChatMessage,
    saveNote,
  };
};

export default useApi;
