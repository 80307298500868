import React, { useEffect } from 'react';

const ContactUs = () => {
 useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);	
  useEffect(() => {
    if (!document.querySelector('#hubspotForm > iframe')) {
      const script = document.createElement('script');
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.charset = "utf-8";
      script.type = "text/javascript";
      script.onload = () => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "47638375",
          formId: "0edfb5a6-1b23-4220-b4bc-7904a82b7fb8",
          target: "#hubspotForm"
        });
      };

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Contact Us</h1>
      <div id="hubspotForm" style={styles.form}></div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    paddingTop : '50px'
  },
  heading: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    width: '100%',
    maxWidth: '600px', 
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
};

export default ContactUs;

